import React from 'react';

const useStateWithLocalStorage = (localStorageKey, defaultValue) => {
  const defaultToUse = defaultValue ? defaultValue : '';

  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || defaultToUse
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
